import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  overflow: hidden;
  height: 100%;
`

const Logo = styled.div`
  position: fixed;
  bottom: 40px;
  left: 60px;
  color: white;
  font-family: pirulen,sans-serif;
  font-weight: 700;
  font-style: normal;
  opacity: 0.1;
  font-size: 20px;
`

function App() {
  let randomIndex = Math.floor(Math.random() * 2) + 1

  return (
    <Container>
      <Logo>TESSERACTS</Logo>
      <video autoplay="true" muted loop style={{ width: '100%', height: '100vh', objectFit: 'cover' }}>
        <source src={`https://tesseracts.s3-us-west-1.amazonaws.com/videos/tesseracts_${randomIndex}.mp4`} type="video/mp4" />
      </video>
      <audio src="https://tesseracts.s3-us-west-1.amazonaws.com/audio/tesseracts_2.mp3" autoPlay={true} loop={true} controls={true}/>
    </Container>
  )
}

export default App
